function addressSelected() {
    let place = autocomplete.getPlaces();
    console.log(place);
}


function getReps(formatted_address) {
  var address = encodeURIComponent(formatted_address)

  fetch(`https://civicinfo.googleapis.com/civicinfo/v2/representatives?levels=country&address=${address}&key=AIzaSyBDsBtMHAU5bXaAsYvI0_u3EBMp9I53K2U`)
      .then(res => res.json())
      .then((out) => {
          console.log('Output: ', out);
  }).catch(err => console.error(err));

}

function initAutocomplete() {
    console.log("Init Form");
    let input = document.getElementById('ac-address');

    let options = {
        componentRestrictions: { country: 'us' },
        types: ['address'],
        fields: ['address_component', 'geometry', 'formatted_address'],
    };

    let autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener('place_changed', function() {
      console.log("PLACE CHANGED")
      let place = autocomplete.getPlace();
      console.log(place);
      getReps(place.formatted_address);
    });
}
